import * as React from 'react';
import { Link } from 'gatsby';
import {
  Container,
  Grid,
  Label,
  List,
  ListItem,
  Message,
} from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2, H3 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUsFormModal } from '../../../components/contact_us_form';

function SeismicHazardDashboard() {
  return (
    <>
      <CenteredImage>
        <StaticImage
          src="./resources/dt_geo_logo.png"
          alt="DT GEO Logo."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <H1>Ground motion modeling dashboard</H1>
      <p>
        Simulation-based seismic hazard assessment is both important to{' '}
        <Link to="/case_studies/urgent_computing/">
          urgent disaster response
        </Link>{' '}
        as well as to efforts to{' '}
        <Link to="/case_studies/seismic_hazard/">bridging the data gap</Link>{' '}
        between observations of background seismicity and possible high
        magnitude events. It is therefore important that running accurate
        simulations at scale is accessible to researchers without the overhead
        of learning new programming languages or recurring simulation details.
        At Mondaic, we want to make our powerful Salvus software usable to a
        wide audience.
      </p>
      <p>
        Part of our work on the DT-Geo project has been to distill our
        experience of running large-scale simulations for Earthquake hazard
        analysis into reproducible workflows that require minimal repetitive
        setup, while enabling the host of details that Salvus can accomodate.
      </p>
      <p>
        To this extent, we implemented a seismic hazard dashboard that can
        dispatch fully functional Salvus simulations from the browser, while
        still taking advantage of:
        <ul>
          <li>
            <b>Fully 3D models</b>.
          </li>
          <li>
            <b>Extensive source descriptions and customizable domains</b>.
          </li>
          <li>
            <b>HPC acceleration</b>.
          </li>
          <li>
            <b>Rapid analysis and flexible outputs</b>.
          </li>
        </ul>
      </p>
      <p>
        In this case study, we demonstrate how our dashboard allows users to
        setup high-fidelity ground motion simulations, and dispatch them to the
        Salvus engine.
      </p>
      <H2>Setting up a ground motion analysis</H2>
      <p>
        To simulate the ground motion response to a seismic event, Salvus needs
        multiple inputs, as well as a configuration describing how to run the
        simulations. The dashboard takes the user through these steps in the
        following order.
      </p>
      <H3>Event and domain setup</H3>
      <p>
        The first step is to determine which event Salvus should simulate. This
        is done by supplying a{' '}
        <Link to="https://quake.ethz.ch/quakeml/"> QuakeML file</Link>. These
        can be obtained by e.g. querying the{' '}
        <Link to="https://service.iris.edu/ph5ws/event/1/">
          IRIS event service
        </Link>{' '}
        for existing events, or can be generated at will for hypothesized
        events. In this demo, we'll use the QuakeML file for a 6.6 Mw event in
        central Italy on October 30th, 2016, as{' '}
        <Link to="http://ds.iris.edu/spud/momenttensor/13285150">
          {' '}
          listed here
        </Link>
        .
      </p>
      <CenteredImage caption="Located moment tensor as visualized on the IRIS Searchable Product Depository.">
        <StaticImage
          src="./resources/Image 1.png"
          alt="Located moment tensor as visualized on the IRIS Searchable Product Depository."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <p>
        The first page of the dashboard, the 'Problem Configuration', allows one
        to load an event from a QuakeML file. Doing this will prompt the user
        with an additional domain widget. This widget allows the user to select
        the extent of the simulation simply by setting a radius in meters,
        giving the user control over the trade-off between computational cost
        and domain of analysis. The snapshot below shows the simulation domain
        created from a radius of 20 kilometers combined with the central Italy
        event.
      </p>
      <CenteredImage caption="A snapshot of the dashboard's 'Problem Configuration'-page after uploading a QuakeML file.">
        <StaticImage
          src="./resources/Image 2.png"
          alt="A snapshot of the dashboard's 'Problem Configuration'-page after uploading a QuakeML file."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <H3>Setting up the simulation and computational resources</H3>
      <p>
        The next page of the dashboard allows users to specify where and how the
        Salvus simulation is run. Using Salvus' built in handling of remote
        resources, any computational resource that can be defined in Salvus,
        e.g. local machines, SSH-based connections or even HPC schedulers like
        SLURM, can be addressed via the web interface. This allows users to
        scale their work beyond their local workstation's computational
        limitations.
      </p>
      <p>
        Additionally, this 'Simulation Configuration' page also allows user to
        further control the trade-off between simulation accuracy and
        computational expense by defining the temporal and spatial resolution of
        the simulations. This is done by setting the maximum resolved frequency
        in Hertz. Using a higher frequency means that the mesh generated will
        contain more elements, both raising{' '}
        <Link to="/docs/knowledge_base/mesh_generation/">
          the resolving power of the simulation as well as the computational
          cost
        </Link>
        .
      </p>
      <CenteredImage caption="An example setup of the 'Simulation Configuration' when simulating up to 5.99 Hz on a remote HPC cluster from the dashboard.">
        <StaticImage
          src="./resources/Image 3.png"
          alt="An example setup of the 'Simulation Configuration' when simulating up to 5.99 Hz on a remote HPC cluster from the dashboard."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <p>
        For this example, we'll simulate at 1 Hz with a resolution of 1.25 wavelengths
        per element.
      </p>
      <H3>Using 3D models</H3>
      As the simulation engine Salvus is a fully 3D Spectral Element solver, one
      can take full advantage of the method to get the highest fidelity ground
      motion modelling. This means that ideally,{' '}
      <Link to="/case_studies/seismic_hazard">
        one includes as much subsurface and geometrical information as possible
      </Link>
      , to get the most accurate modelling. The dashboard allows users to
      integrate multiple sources of subsurface information.
      <CenteredImage caption="An example setup of the 'Model Configuration' when simulating a layered velocity model using the USGS global VS30 model. Partially shown at the bottom is a visualization of the local VS30 model.">
        <StaticImage
          src="./resources/Image 4.png"
          alt="An example setup of the 'Model Configuration' when simulating a layered velocity model using the USGS global VS30 model. Partially shown at the bottom is a visualization of the local VS30 model."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <ul>
        <li>
          <b>Velocity models</b>: The dashboard allows one to create a
          repository of layered velocity models as well as volumetric velocity
          models, which can be loaded at will per event investigated.
        </li>
        <li>
          <b>Topographic information</b>: The dashboard will automatically
          download the relevant topography at the appropriate resolution for the
          frequencies simulated.
        </li>
        <li>
          <b>Shallow subsurface structure</b>: Using model blending techniques,
          the dashboard can automatically combine volumetric velocity models for
          the deep (100m+) subsurface and the topography with an average shallow
          shear-velocity, or VS30, model.
        </li>
      </ul>
      <p>
        All these models are integrated using Salvus' advanced{' '}
        <Link to="/docs/examples/tutorials/meshing/layered_meshing/03_complex_geophysical_domains">
          layered mesher
        </Link>{' '}
        interface. By defining standard data sources for topograhy (
        <Link to="https://www.gmrt.org/">GMRT</Link>) and VS30 (
        <Link to="https://earthquake.usgs.gov/data/vs30/">USGS</Link>) and using
        the layered mesher interface, user input at this step is minimized,
        allowing rapid prototyping and production. More data sources can be
        added by the user. In the central Italy example, a layered velocity
        model is used, combined with the GMRT topography and USGS's VS30 model.
      </p>
      <H2>Running simulations</H2>
      <p>
        Launching the completed configuration is done simply from the 'Run' page
        of the dashboard. Pressing the launch workflow button will dispatch the
        configured Salvus simulation to local or remote resources
        asynchronously, allowing a user to immediately create more variations to
        be dispatched or queued. This page also functions as a log of all
        previously ran simulations.
      </p>
      <CenteredImage caption="Running a workflow from the 'Run' page of the dashboard.">
        <StaticImage
          src="./resources/Image 5.png"
          alt="Running a workflow from the 'Run' page of the dashboard."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <H2>Ground motion output</H2>
      <p>
        The simulations that are dispatched from the dashboard record all ground
        motion in the domain. What this means is that for every location, a time
        history of the movement is recorded during the simulation. This is a
        very flexible output format, as it allows a user to:
        <ul>
          <li>
            <b>Compute and visualize derived metrics</b> directly in the
            dashboard such as Peak Ground Acceleration (PGA) or Peak Spectral
            Acceleration (PSA).
          </li>
          <li>
            <b>Allow the export of time series data</b> for further custom
            analysis by the user.
          </li>
        </ul>
        <p>
          The dashboard giving access to key simulation metrics such as PGA is
          essential for users to do rapid development of their risk analysis.
          Shown below are the results for the event in Italy. Notable is that
          the distribution of PGA values is highly dependent only spatial
          variations of topography and VS30.
        </p>
        <CenteredImage caption="Output dashboard, with event details and the simulation's PGA output. Note that the output metric also includes hill-shading, as to help with identifying spatial extent.">
          <StaticImage
            src="./resources/Image 6.png"
            alt="Output dashboard, with event details and the simulation's PGA output."
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
        <p>
          Although using this dashboard allows for quick development cycles of
          hazard assessment while evaluating key metrics, it should be noted
          that it is powered by Salvus' full generality: all wavefield
          simulation's outputs are also available on disk. Using e.g.{' '}
          <Link to="/docs/references/visualization">
            Paraview's powerful visualization
          </Link>
          , these results can be interpreted in detail, or even loaded into
          Python using{' '}
          <Link to="/docs/examples/tutorials/data_analysis/wavefield_output/tutorial">
            h5py or Salvus' WavefieldOutput objects
          </Link>
          .
        </p>
        <CenteredImage caption="Time-series surface output (at 1 Hz) of the workflow visualized with ParaView. Shown below the simulation domain is the simulation mesh, for which the time-series data is not fully stored. Not the directional variation in the wavefield caused by local topography and source directivity.">
          <StaticImage
            src="./resources/Image 7.png"
            alt="Time-series surface output of the workflow visualized with ParaView."
            quality={100}
            placeholder="tracedSVG"
          />
        </CenteredImage>
      </p>
      <H2>Funding</H2>
      <p>
        This work has received funding from the European Union's Horizon Europe
        research and innovation programme under grant agreement no.{' '}
        <b>101058129</b> (DT-Geo). Visit the{' '}
        <a href="https://dtgeo.eu" target="_blank">
          DT Geo website
        </a>{' '}
        for more information.
      </p>
      <CenteredImage>
        <StaticImage
          src="./resources/dt_geo_logo.png"
          alt="DT GEO Logo."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <Grid style={{ marginTop: '5ex' }}>
        <Grid.Row centered>
          The Salvus software suite is Mondaic's flagship code to solve problems
          like the above
        </Grid.Row>
        <Grid.Row centered>... and many more!</Grid.Row>
        <Grid.Row centered>
          <ContactUsFormModal />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default withLayout(SeismicHazardDashboard);
